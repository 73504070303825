import * as React from "react";
import { FC } from "react";
import { WelcomeLinks } from "../../components/welcome/welcome-links";
import { WelcomePainting } from "../../components/welcome/welcome-painting";
import { LANG } from "../../common/types/localization";
import { Paper } from "../../common/components/canvas/paper";

import parchentClasses from "../../common/css/backgrounds.module.css";

export default (): FC => (
  <>
    <div style={{ margin: "100px" }}>
      <Paper className={parchentClasses.parchment}>
        <div style={{ margin: "25px" }}>
          asdölkasdfö lasdfjöaslf jaösdlfja sdöflkas dföaksdjföaks
          dfjöalsdkjfökasd a sdölkasdj sd fas aöslkjasd öa dsföasdf asdölasd
          faösdf asdöflaksdf asdf asödflksfj asdölaksdf aösdflka sdföalskdfa
          ösdfasldkfa sdföalsdkfjaös dfaösdflkjasdöf laksdfjaö sdföasdlkfjas
          dföasdfklja sdföasd flkjasdöfkasjdf asdölkasdfö lasdfjöaslf jaösdlfja
          sdöflkas dföaksdjföaks dfjöalsdkjfökasd a sdölkasdj sd fas aöslkjasd
          öa dsföasdf asdölasd faösdf asdöflaksdf asdf asödflksfj asdölaksdf
          aösdflka sdföalskdfa ösdfasldkfa sdföalsdkfjaös dfaösdflkjasdöf
          laksdfjaö sdföasdlkfjas dföasdfklja sdföasd flkjasdöfkasjdf
          asdölkasdfö lasdfjöaslf jaösdlfja sdöflkas dföaksdjföaks
          dfjöalsdkjfökasd a sdölkasdj sd fas aöslkjasd öa dsföasdf asdölasd
          faösdf asdöflaksdf asdf asödflksfj asdölaksdf aösdflka sdföalskdfa
          ösdfasldkfa sdföalsdkfjaös dfaösdflkjasdöf laksdfjaö sdföasdlkfjas
          dföasdfklja sdföasd flkjasdöfkasjdf
        </div>
      </Paper>
    </div>
    <WelcomeLinks lang={LANG.deCH} />
    <WelcomePainting />
  </>
);
